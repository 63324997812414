import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import SEO from "../components/seo";
import NewsletterBanner from "../components/newsletterBanner";

const query = graphql`
  query {
    allStrapiPage(limit: 4, sort: { fields: publication_date, order: DESC }) {
      nodes {
        id
        Titolo
        Permalink
        Copertina {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout>
      <SEO title="Codice etico - Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper overflow-hidden relative sterea-blue-gradient">
        <div className="sterea-center-column h-screen z-20 flex justify-center items-center">
          <h1 className="w-1/2 text-white text-center text-xl lg:text-5xl leading-normal font-bold">
            Codice Etico
          </h1>
        </div>
      </div>

      <div className="sterea-center-column-wrapper">
        <div className="sterea-center-column px-min">
          <div className="sterea-content-container py-lg lg:w-full">
            <h2>Corretta informazione ai clienti-pazienti</h2>

            <p>
              Solo i medici possono essere specialisti competenti in campo della
              medicina. Per questo diamo loro la possibilità di:
            </p>

            <ul>
              <li>
                rendersi partecipi nella creazione del contenuto del nostro sito
              </li>
              <li>
                impedire la diffusione di false e/o eccessive aspettative ai
                clienti-pazienti
              </li>
              <li>pubblicare consigli nei forum di discussione</li>
              <li>
                pubblicare descrizioni di interventi (per la chirurgia plastica
                e medicina estetica)
              </li>
              <li>pubblicare video educativi, etc.</li>
            </ul>

            <p>
              ​Sicurezza dei pazienti – siamo convinti che solo il paziente
              informato può prendere decisioni consapevoli. Il nostro impegno è
              quello di selezionare le migliori cliniche in area europea ed
              extra europea per ogni Vostra specifica esigenza al miglior
              rapporto qualità prezzo.
            </p>

            <h2>La medicina non è una scienza esatta</h2>
            <p>
              In medicina esistono varie tecniche e possibilità di trattamento
              possono a volte consentire il raggiungimento di risultati
              ottimali. I medici possono presentare metodi e approcci diversi e
              spiegarne i punti fondamentali, per tal motivo la società Sterea
              si impegna nei confronti dei propri clienti a collaborare solo con
              quelle cliniche altamente qualificate e certificate.
            </p>

            <h2>Il diritto al secondo parere</h2>
            <p>
              I pazienti hanno il diritto di consultare più di uno specialista o
              clinica per poter prendere delle decisioni mature e consapevoli,
              ogni consiglio o consulenza da parte della società Sterea sarà
              nell'esclusivo interesse del cliente-paziente.
            </p>

            <h2>Le discussioni moderate</h2>
            <p>
              Le informazioni pubblicate sul Nostro sito sono state
              preventivamente controllate e attentamente verificate e soggette a
              verifica costante da parte dei nostri moderatori che osservano il
              forum e il contenuto delle discussioni per evitare la diffusione
              di messaggi non veritieri, volgari, offensivi e di quelli a
              carattere commerciale o pubblicitario.
            </p>

            <h2>La collaborazione con società scientifiche</h2>
            <p>
              Manteniamo una stretta collaborazione con associazioni e società
              scientifiche per soddisfare i requisiti stabiliti dalle varie
              legislazioni nazionali e per garantire la divulgazione di
              informazioni corrette e affidabili.
            </p>

            <h2>La libera scelta</h2>
            <p>
              Il medico o la Clinica sono attentamente selezionati dalla società
              Sterea e prima della loro registrazione sul nostro sito vengono
              valutati dal nostro personale mediante sopralluoghi mirati presso
              cliniche ed ambulatori informati su tutte le condizioni necessarie
              per collaborare con la nostra società, il tutto per garantire al
              cliente-paziente la massima qualità e serietà.
            </p>

            <h2>La comunicazione trasparente</h2>
            <p>
              I clienti-pazienti ricevono in modo trasparente e aperto
              informazioni. Queste informazioni non vengono mai comunicate con
              effetto retroattivo ma sempre con un sufficiente anticipo. Ogni
              Cliente ha un nostro consulente personale che è a sua disposizione
              per l'assistenza o per i chiarimenti relativi alla gestione della
              propria situazione, in tal senso la Soc. Sterea fungerà da tramite
              tra clinica e cliente-paziente per tutte le comunicazioni ed
              informazioni di cui lo stesso necessiterà.
            </p>
            <p>
              L'invio di richieste da parte dei pazienti viene moderato e
              all'occorrenza tradotto dal nostro personale. Tutte le
              informazioni fornite dai clienti-pazienti inerenti il tipo di
              trattamento e/o intervento sono trattate secondo le più
              restrittive norme vigenti sulla privacy e custodite secondo tali
              norme.
            </p>

            <h2>Fair play</h2>
            <p>
              La nostra società si distingue per l'estrema serietà e
              professionalità e pertanto prende in modo chiaro e netto le
              distanze da pratiche disoneste a volte presenti sul web. A tutti i
              nostri medici e cliniche nostri partners e ai nostri
              clienti-pazienti garantiamo una comunicazione chiara e
              trasparente.
            </p>
          </div>
        </div>
      </div>

      <NewsletterBanner />
    </Layout>
  );
};

export default IndexPage;
